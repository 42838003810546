import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private adminDetails = new BehaviorSubject<any>(null);
  constructor(public http: HttpClient, private httpService: HttpService) {}

  public getTabConfigItem() {
    const url = 'user/getuserconfigs?tabName=admin';
    return this.httpService.get(url);
  }

  public getAdminConfig(): Observable<any> {
    const url = 'user/getuserconfigs?tabName=admin';
    return this.httpService.get(url);
  }

  getAdminDetails(): Observable<any> {
    return this.adminDetails.asObservable();
  }

  updateConfig(newData: any): void {
    this.adminDetails.next(newData);
  }

  public getAllTabs() {
    return this.http.get<any>(`${environment.baseUrl}admin/getalltabs`);
  }

  public getTabConfigItems(tabName: string) {
    return this.http.get<any>(`${environment.baseUrl}admin/gettabconfig/${tabName}`);
  }

  public updateSettings(payload: any, tabId: string): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}admin/updatetabconfig/${tabId}`, payload);
  }

  public getAllRoles() {
    return this.http.get<any[]>(`${environment.baseUrl}admin/getallroles`);
  }
}
