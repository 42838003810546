/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-case-declarations */
import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { AdminTab, AppRole } from 'src/app/shared/models/admin-dashboard.modal';
import { forkJoin, of } from 'rxjs';
import { AdminService } from 'src/app/shared/services/admin.service';
import { AdminSettingsComponent } from '../admin-settings/admin-settings.component';

import { DashboardSettingsComponent } from '../dashboard-settings/dashboard-settings.component';
import { NativeSoilSettingsComponent } from '../native-soil-settings/native-soil-settings.component';
import { PrecastSettingsComponent } from '../precast-settings/precast-settings.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  tabsData: AdminTab[] = [];
  tabIndex = 0;
  appRoles: AppRole[] = [];
  @ViewChild('tabContent', {
    read: ViewContainerRef,
  })
    tabContent: ViewContainerRef;

  constructor(
    private router: Router,
    private adminService: AdminService,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {}

  ngOnInit() {
    this.fetchTabsData();
  }

  // Component get tab name and convert it to uppercase
  getTabName(tab: AdminTab): string {
    return tab && tab.tabName ? tab.tabName.toUpperCase() : '';
  }

  fetchTabsData() {
    const tabs$ = this.adminService.getAllTabs() || of([]);
    const roles$ = this.adminService.getAllRoles() || of([]);

    forkJoin([tabs$, roles$]).subscribe({
      next: ([allTabs, allRoles]) => {
        this.tabsData = allTabs;
        this.appRoles = allRoles as AppRole[];
      },
      error: (err) => {
        console.error('Error fetching tabs or roles:', err);
      },
    });
  }

  // Component method to handle tab selection change
  componentTabs(event: number) {
    // within the bounds of the tabsData array
    if (event >= 0 && event < this.getFilteredTabs().length) {
      this.tabIndex = event;
      const tabId = this.getFilteredTabs()[event]?.id;
      this.getComponent(tabId);
    } else {
      console.log('Invalid tab');
    }
  }

  // Load component dynamically based on selected tab
  getComponent(tabId: string) {
    this.tabContent.clear();
    const selectedTab = this.tabsData.find((tab) => tab.id === tabId);
    if (selectedTab) {
      switch (selectedTab.tabName.toUpperCase()) {
        case 'DASHBOARD':
          const dashboardsettingsFactory = this.componentFactoryResolver.resolveComponentFactory(DashboardSettingsComponent);
          const dashboardsettingsComponentRef = this.tabContent.createComponent(dashboardsettingsFactory);
          dashboardsettingsComponentRef.instance.tabId = tabId; // Pass tabId to component
          dashboardsettingsComponentRef.instance.appRoles = this.appRoles;
          break;
        case 'ADMIN':
          const adminsettingsFactory = this.componentFactoryResolver.resolveComponentFactory(AdminSettingsComponent);
          const adminsettingsComponentRef = this.tabContent.createComponent(adminsettingsFactory);
          adminsettingsComponentRef.instance.tabId = tabId; // Pass tabId to component
          break;
        case 'PRE-CAST WORKSHEET':
          const preCast_SettingsFactory = this.componentFactoryResolver.resolveComponentFactory(PrecastSettingsComponent);
          const preCast_SettingsComponentRef = this.tabContent.createComponent(preCast_SettingsFactory);
          preCast_SettingsComponentRef.instance.tabId = tabId; // Pass tabId to component
          preCast_SettingsComponentRef.instance.appRoles = this.appRoles;
          break;
        case 'NATIVE SOIL WORKSHEET':
          const native_Soil_settingsFactory = this.componentFactoryResolver.resolveComponentFactory(NativeSoilSettingsComponent);
          const native_Soil_settingsComponentRef = this.tabContent.createComponent(native_Soil_settingsFactory);
          native_Soil_settingsComponentRef.instance.tabId = tabId; // Pass tabId to component
          native_Soil_settingsComponentRef.instance.appRoles = this.appRoles;
          break;
        default:
          console.error('Component not found for tab:', selectedTab.tabName);
      }
      // router url showing the id of the component
      this.router.navigate([], {
        queryParams: {
          tabId,
        },
      });
    } else {
      console.error('Tab not found with ID:', tabId);
    }
  }

  // Filter and sort tabs based on parentId = null and sort-order
  getFilteredTabs(): AdminTab[] {
    return this.tabsData.filter((tab) => tab.parentId === null).sort((a, b) => a.sortOrder - b.sortOrder);
  }

  handleTabClick(tabIndex: number) {
    if (tabIndex >= 0 && tabIndex < this.tabsData.length) {
      this.tabIndex = tabIndex;
      const tabId = this.tabsData[tabIndex]?.id;
      this.getComponent(tabId);
    }
  }

  // Dashboard main page redirect
  reDirectUrl() {
    this.router.navigate(['dashboard']);
  }
}
