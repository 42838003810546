import { Component, Input, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { AdminDashboard, AdminTab, AppRole } from 'src/app/shared/models/admin-dashboard.modal';
import { IMessage } from 'src/app/shared/models/message.modal';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MessageModalComponent } from 'src/app/shared/components/message-modal/message-modal.component';
import { messagesConfig } from 'src/app/shared/constants/message.constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminService } from 'src/app/shared/services/admin.service';

@Component({
  selector: 'app-native-soil-settings',
  templateUrl: './native-soil-settings.component.html',
  styleUrls: ['./native-soil-settings.component.scss'],
})
export class NativeSoilSettingsComponent implements OnInit {
  @Input() tabId: string;
  nativeSoilData: AdminDashboard[] = [];
  filteredTabs: AdminTab[] = [];
  selectedTabId = '';
  appRoles: AppRole[] = [];

  successMessage: IMessage;
  public updateSuccess: MatDialogRef<MessageModalComponent>;
  constructor(
    private adminService: AdminService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.fetchNativeSoil();
  }

  fetchNativeSoil(): void {
    this.adminService.getAllTabs().subscribe({
      next: (response: AdminTab[]) => {
        this.filteredTabs = response
          .filter((item) => item.parentId === this.tabId)
          .sort((a, b) => a.sortOrder - b.sortOrder);
      },
      error: (error) => console.error('Error fetching tabs data:', error),
    });
  }

  onTabChange(event: MatTabChangeEvent): void {
    const selectedTab = this.filteredTabs[event.index];
    if (selectedTab) {
      this.selectedTabId = selectedTab.id;
      this.getSelectedNativeSoil(this.selectedTabId);
    }
  }

  getSelectedNativeSoil(tabId: string): void {
    this.adminService.getTabConfigItems(tabId).subscribe({
      next: (response: AdminDashboard[]) => {
        this.nativeSoilData = response.sort((a, b) => a.sortOrder - b.sortOrder);
      },
      error: (error) => console.error('Error fetching tabs data:', error),
    });
  }

  saveNativeSoil(): void {
    this.adminService.updateSettings(this.nativeSoilData, this.selectedTabId).subscribe({
      next: (response: AdminDashboard[]) => {
        this.nativeSoilData = response;
        this.snackBar.open(messagesConfig.changeSuccessMeaage.message, 'DONE', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['default-snackbar'],
        });
        this.getSelectedNativeSoil(this.selectedTabId);
      },
      error: (error) => console.error('Error API:', error),
    });
  }

  isTabActive(tabId: string): boolean {
    return this.selectedTabId === tabId;
  }
}
